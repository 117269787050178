var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticStyle: { height: "80px" } }),
      _c(
        "div",
        { staticClass: "table" },
        [
          _vm._m(0),
          _vm._l(_vm.tableData, function(item, index) {
            return _c("div", { key: index, staticClass: "table-tr" }, [
              _c("div", { staticClass: "server-type" }, [
                _vm._v(_vm._s(item.type))
              ]),
              _c(
                "div",
                { staticClass: "server-list-box" },
                _vm._l(item.list, function(server, i) {
                  return _c("div", { key: i, staticClass: "server-list" }, [
                    _c("div", { staticClass: "server-content" }, [
                      _vm._v(_vm._s(server.content))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "server-free",
                        class: _vm._f("fStyle")(server.free)
                      },
                      [_vm._v(_vm._s(server.free))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "server-ordinary",
                        class: _vm._f("fStyle")(server.ordinary)
                      },
                      [_vm._v(_vm._s(server.ordinary))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "server-high",
                        class: _vm._f("fStyle")(server.high)
                      },
                      [_vm._v(_vm._s(server.high))]
                    )
                  ])
                }),
                0
              )
            ])
          })
        ],
        2
      ),
      _c("div", { staticStyle: { height: "80px" } }),
      _c("Foot")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-header" }, [
      _c("div", { staticClass: "server" }, [_vm._v("服务列表")]),
      _c("div", { staticClass: "version-type free" }, [
        _vm._v(" 免费版 "),
        _c("div", { staticClass: "btn" }, [_vm._v("免费开通")])
      ]),
      _c("div", { staticClass: "version-type ordinary" }, [
        _vm._v(" 普通版 "),
        _c("div", { staticClass: "btn" }, [_vm._v("立即购买")])
      ]),
      _c("div", { staticClass: "version-type high" }, [
        _vm._v(" 高级版 "),
        _c("div", { staticClass: "btn" }, [_vm._v("立即购买")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }