<template>
<div class="container">
  <div style="height: 80px"></div>
  <div class="table">
<!--    表头-->
    <div class="table-header">
      <div class="server">服务列表</div>
      <div class="version-type free">
        免费版
        <div class="btn">免费开通</div>
      </div>
      <div class="version-type ordinary">
        普通版
        <div class="btn">立即购买</div>
      </div>
      <div class="version-type high">
        高级版
        <div class="btn">立即购买</div>
      </div>
    </div>
<!--    数据-->
    <div class="table-tr" v-for="(item, index) of tableData" :key="index">
      <div class="server-type">{{ item.type }}</div>
      <div class="server-list-box">
        <div class="server-list" v-for="(server, i) of item.list" :key="i">
          <div class="server-content">{{ server.content }}</div>
          <div class="server-free" :class="server.free | fStyle">{{ server.free }}</div>
          <div class="server-ordinary" :class="server.ordinary | fStyle">{{ server.ordinary }}</div>
          <div class="server-high" :class="server.high | fStyle">{{ server.high }}</div>
        </div>
      </div>
    </div>
  </div>
  <div style="height: 80px"></div>
  <Foot />
</div>
</template>

<script>
import Foot from '@/components/footer.vue'
export default {
  name: 'ModalPrice',
  components: {
    Foot
  },
  filters: {
    fStyle (str) {
      if (str === '√') {
        return 'green'
      } else if (str === '×') {
        return 'orange'
      } else {
        return ''
      }
    }
  },
  data () {
    return {
      tableData: [
        {
          type: '服务类型',
          list: [
            {
              content: '服务内容',
              free: '免费',
              ordinary: '1360（每年）',
              high: '4078（每年）'
            }
          ]
        },
        {
          type: '活动营销',
          list: [
            {
              content: '抽奖（大转盘、摇一摇、定时抽奖等）',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '游戏营销',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '涨粉/推广活动',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '现场活动',
              free: '×',
              ordinary: '×',
              high: '√'
            },
            {
              content: '促销活动（拼团、秒杀等）',
              free: '×',
              ordinary: '×',
              high: '√'
            },
            {
              content: '留存活动（积分乐园、种树等）',
              free: '×',
              ordinary: '×',
              high: '√'
            },
            {
              content: '投票活动',
              free: '×',
              ordinary: '×',
              high: '√'
            },
            {
              content: '答题活动',
              free: '×',
              ordinary: '×',
              high: '√'
            },
            {
              content: '红包活动',
              free: '×',
              ordinary: '×',
              high: '√'
            }
          ]
        },
        {
          type: '接口服务',
          list: [
            {
              content: '将活动嵌入自有APP/小程序',
              free: '×',
              ordinary: '×',
              high: '付费支持'
            },
            {
              content: '自定义参与门槛',
              free: '×',
              ordinary: '×',
              high: '付费支持'
            },
            {
              content: '打通商家积分数据',
              free: '×',
              ordinary: '×',
              high: '付费支持'
            },
            {
              content: '使用商家系统自有礼品',
              free: '×',
              ordinary: '×',
              high: '付费支持'
            }
          ]
        },
        {
          type: '活动传播',
          list: [
            {
              content: '单个活动浏览人数上限',
              free: '不限',
              ordinary: '不限',
              high: '不限'
            },
            {
              content: '单个活动分享次数上限',
              free: '不限',
              ordinary: '不限',
              high: '不限'
            },
            {
              content: '单个活动参与人数上限',
              free: '100人',
              ordinary: '10000人',
              high: '不限'
            },
            {
              content: '单个活动投票人数上限',
              free: '不限',
              ordinary: '10000人',
              high: '不限'
            },
            {
              content: '单个活动设置奖品上限',
              free: '10份',
              ordinary: '10000份',
              high: '1000000份'
            }
          ]
        },
        {
          type: '账号设置',
          list: [
            {
              content: '总帐号数量',
              free: '1个',
              ordinary: '1个',
              high: '10个'
            },
            {
              content: '公众号绑定数量',
              free: '1个',
              ordinary: '1个',
              high: '3个'
            },
            {
              content: '同时创建活动数上限',
              free: '1个',
              ordinary: '5个',
              high: '5000个'
            },
            {
              content: '同时发布活动数上限',
              free: '1个',
              ordinary: '5个',
              high: '50个'
            },
            {
              content: '活动发布总次数',
              free: '1次/月',
              ordinary: '不限',
              high: '不限'
            }
          ]
        },
        {
          type: '品牌标识',
          list: [
            {
              content: '购买去广告券（1张）',
              free: '400元',
              ordinary: '100元（2.5折）',
              high: '无需购买'
            },
            {
              content: '购买去广告券包（10张）',
              free: '4000元',
              ordinary: '500元（1.25折）',
              high: '无限购买'
            },
            {
              content: '免广告权限',
              free: '×',
              ordinary: '×',
              high: '√'
            },
            {
              content: '登录页广告',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '主办单位',
              free: '×',
              ordinary: '√',
              high: '不限'
            },
            {
              content: '协办单位',
              free: '×',
              ordinary: '√',
              high: '不限'
            }
          ]
        },
        {
          type: '微信红包',
          list: [
            {
              content: '平台代发微信红包',
              free: '×',
              ordinary: '×',
              high: '√'
            },
            {
              content: '平台代发红包充值金额上限（每季度）',
              free: '×',
              ordinary: '×',
              high: '50000'
            },
            {
              content: '接入自有微信商户号（不限派发金额）',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '红包手续费',
              free: '×',
              ordinary: '×',
              high: '4%'
            }
          ]
        },
        {
          type: '自定义设置',
          list: [
            {
              content: '自定义分享内容',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '自定义奖品说明',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '自定义分数单位',
              free: '×',
              ordinary: '√',
              high: '√'
            }
          ]
        },
        {
          type: '活动功能',
          list: [
            {
              content: '分享活动设置',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '安慰奖设置',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '联系信息',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '参与地区限制',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '仅群成员可参与',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '访问密码',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '粉丝专属',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '组队参与',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '推广有礼',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '轮播获奖信息功能',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '奖品介绍',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '客服兑奖',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '活动说明（图文）',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '本地上传单个视频大小限制',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '积分营销',
              free: '×',
              ordinary: '√',
              high: '√'
            }
          ]
        },
        {
          type: '活动奖品',
          list: [
            {
              content: '礼品',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '嘿卡优惠券',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '手机话费',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '爱奇艺黄金会员',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '优酷土豆黄金会员',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '腾讯视频VIP会员',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '微信现金红包',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '裂变优惠券',
              free: '√',
              ordinary: '√',
              high: '√'
            }
          ]
        },
        {
          type: '高级服务',
          list: [
            {
              content: '并发访问',
              free: '50/s',
              ordinary: '6000/s',
              high: '15000+/s'
            },
            {
              content: 'CDN网络加速',
              free: '×',
              ordinary: '150%',
              high: '300%'
            },
            {
              content: '反作弊系统白名单设置',
              free: '×',
              ordinary: '√',
              high: '√'
            },
            {
              content: '华为云防作弊',
              free: '×',
              ordinary: '√',
              high: '√'
            }
          ]
        },
        {
          type: '活动数据',
          list: [
            {
              content: '浏览人数统计',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '参与人数统计',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '分享人数统计',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '获奖人数统计',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '数据趋势统计',
              free: '√',
              ordinary: '√',
              high: '√'
            },
            {
              content: '多渠道统计',
              free: '√',
              ordinary: '√',
              high: '√'
            }
          ]
        }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import "./css/ModalPrice.scss";
</style>
